import React from 'react';

//domains
import type {SanityTwoCols} from 'domains/modules';

//components
import Module from 'components/modules/Module';

export default function TwoCols({module}: {module: SanityTwoCols}) {
  return (
    <div className="grid gap-6 md:grid-cols-2 md:gap-13">
      <div className="md:col-span-1">
        <div className="">
          {module.left_column?.map((m) => {
            return <Module key={m._key} module={m} />;
          })}
        </div>
      </div>
      <div className="md:col-span-1">
        <div className="">
          {module.right_column?.map((m) => {
            return <Module key={m._key} module={m} />;
          })}
        </div>
      </div>
    </div>
  );
}
