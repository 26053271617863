import React from 'react';
// import * as R from 'ramda';
import {Await, useLoaderData} from '@remix-run/react';
import {Suspense} from 'react';

//components
import Container from 'components/Container';
import Product from 'components/product/Product';
import {getHeroPlaceholder} from '~/lib/placeholders';

//types
import type {SanityModuleSelectedProducts} from 'domains/modules';

import {loader} from '~/routes/($lang)/index';

interface Props {
  module: SanityModuleSelectedProducts;
}
const SelectedProducts: React.FC<Props> = ({module}) => {
  const {featuredProducts} = useLoaderData<typeof loader>();

  if (featuredProducts) {
    return (
      <Suspense fallback="loading">
        <Await resolve={featuredProducts}>
          {(products) => {
            if (!products) return <></>;
            return (
              <section className="py-14">
                <header className="mb-10">
                  <h2 className="text-center text-xl font-bold uppercase tracking-wide">
                    {module.section_title}
                  </h2>
                </header>
                <Container>
                  <ul className="grid grid-cols-2 gap-y-10 gap-x-5 md:grid-cols-3 xl:grid-cols-5">
                    {featuredProducts.map((p) => {
                      if (p) {
                        return (
                          <Product
                            key={`featured-${p.id}`}
                            product={p}
                            quickAdd
                          />
                        );
                      }
                    })}
                  </ul>
                </Container>
              </section>
            );
          }}
        </Await>
      </Suspense>
    );
  }
  return <></>;
};

export default SelectedProducts;
