import React from 'react';
import {useMatches} from '@remix-run/react';

//components
import Container from 'components/Container';
import SanityImage from 'components/SanityImage';
import {Link} from 'components/Link';

//types
import type {SanityModuleBlogSection} from 'domains/modules';
import type { SanityBlogCategory } from '~/domains/blog';

//data

interface Props {
  module: SanityModuleBlogSection;
}

const BlogSection: React.FC<Props> = ({module}) => {
  const [root] = useMatches();
  const translation = root?.data?.translation as any;
  const categories = root?.data?.categories as SanityBlogCategory[]
  return (
    <section className="section--blogs-section py-7">
      <Container className="bg-hk-gray lg:py-14 py-7">
        <header className="col-span-12 mb-16 text-center">
          <h2 className="text-xl font-bold uppercase tracking-wide">
            {module.section_title}
          </h2>
        </header>
        <div className="mx-auto w-full lg:px-8">
          <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-10">
            <div className="hidden lg:col-span-1 lg:block">
              <div className="grid gap-16">
                {module.most_popular_posts && (
                  <div className="">
                    <h3 className="text-xl tracking-wide mb-5 font-semibold capitalize">
                      {module.most_popular_posts_title}
                    </h3>
                    <ul className="grid gap-6">
                      {module.most_popular_posts.map((b) => {
                        return (
                          <li
                            key={b._rev}
                            className="border-b border-black pb-4"
                          >
                            <article className="blog">
                              <Link
                                to={`/blogs/${b.blog_category.slug.current}/${b.slug.current}`}
                              >
                                <h5 className="line-clamp-2 font-normal">
                                  {b.blog_title}
                                </h5>
                              </Link>
                            </article>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                {categories && (
                  <div className="">
                    <h3 className="text-xl tracking-wide mb-5 font-semibold capitalize">
                      {module.categories_title}
                    </h3>
                    <ul className="grid gap-6">
                      {categories.map((b) => {
                        return (
                          <li
                            key={b._rev}
                            className="border-b border-black pb-4"
                          >
                            <article className="blog">
                              <Link to={`/blogs/${b.slug.current}`}>
                                <h5 className="font-normal">
                                  {b.category_title}
                                </h5>
                              </Link>
                            </article>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            {module.recent_blogs && (
              <ul className="grid gap-16 lg:col-span-2">
                {module.recent_blogs.map((b) => {
                  return (
                    <li key={b._rev} className="">
                      <article className="blog group">
                        <Link
                          to={`/blogs/${b.blog_category.slug.current}/${b.slug.current}`}
                          className="grid gap-4 text-center lg:grid-cols-3 lg:text-left"
                        >
                          <div className="blog__thumbnail overflow-hidden lg:col-span-1">
                            <SanityImage
                              layout="responsive"
                              sizes={['50vw, 100vw']}
                              src={b.blog_thumbnail?.asset._ref}
                              className="transition-transform duration-200 ease-out group-hover:scale-110"
                            />
                          </div>
                          <div className="lg:col-span-2">
                            <h5 className="mb-4 text-sm lg:text-xl">
                              {b.blog_title}
                            </h5>
                            <div className="">
                              <p className="line-clamp-3 text-xs lg:text-sm">
                                {b.blog_description}
                              </p>
                              <div className="mt-2 text-hk-red text-xs lg:text-sm">
                                {translation?.read_more ?? 'Read More'}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </article>
                    </li>
                  );
                })}
              </ul>
            )}
          </div>
        </div>
      </Container>
    </section>
  );
};

export default BlogSection;
