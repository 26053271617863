import type {SanityModule} from 'domains/modules';

//module
import HeroBanner from 'components/modules/HeroBanner';
import SelectedCollections from 'components/modules/SelectedCollections';
import SelectedProducts from 'components/modules/SelectedProducts';
import BlogSection from 'components/modules/BlogSection';
import GridSection from 'components/modules/GridSection';
//   import EmailSignUp from 'components/modules/EmailSignUp';
  import ProductBundle from 'components/modules/ProductBundle';
import SanityCarousel from 'components/modules/SanityCarousel';
import SanityIframe from 'components/modules/SanityIframe';
import LooxReviews from 'components/modules/LooxReviews';
import FullImage from 'components/modules/FullImage';
import TwoCols from 'components/modules/TwoCols';
import SanityRichText from 'components/modules/SanityRichText';
import SanityButton from 'components/modules/SanityButton';
import SocialReviews from 'components/modules/SocialReviews';
import SanityContactUsForm from 'components/modules/SanityContactUsForm';

type Props = {
  module: SanityModule;
};

export default function Module({module}: Props) {
  switch (module._type) {
    case 'module.hero_banner':
      return <HeroBanner module={module} />;
    case 'custom.selectShopifyCollections':
      return <SelectedCollections module={module} />;
    case 'module.socialReviews':
      return <SocialReviews module={module} />;
    case 'module.blogsSection':
      return <BlogSection module={module} />;
    case 'module.gridSection':
      return <GridSection module={module} />;
    case 'module.contactUsForm':
      return <SanityContactUsForm data={module} />;
    // case 'module.emailSignUp':
    //   return <EmailSignUp module={module} />;
    case 'module.productBundle':
      return <ProductBundle bundle_shortcode={module.bundle_shortcode} />;
    case 'module.twoColsGrid':
      return <TwoCols module={module} />;
    case 'module.looxReviews':
      return <LooxReviews module={module} />;
    case 'custom.carousel':
      return <SanityCarousel data={module} />;
    case 'iframe':
      return <SanityIframe module={module} />;
    case 'button':
      return <SanityButton data={module} />;
    case 'module.selectedShopifyProducts':
      return <SelectedProducts module={module} />;
    case 'image':
      return <FullImage module={module} />;
    case 'block':
      if (module.children) {
        return <SanityRichText richtext={module} />;
      }
      return <div className="min-h-[25px] text-md font-normal"></div>;
    default:
      return null;
  }
}
