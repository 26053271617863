import React from 'react';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import clsx from 'clsx';
import * as R from 'ramda';
import {useFetcher} from '@remix-run/react';
import {
  useGoogleReCaptcha,
  GoogleReCaptchaProvider,
  GoogleReCaptcha,
} from 'react-google-recaptcha-v3';

//types
import type {SanityButton} from 'domains/modules';

interface Props {
  data: {
    _key: string;
    _type: 'module.contactUsForm';
    description: string;
    email_address_label: string;
    email_address_placeholder: string;
    message_label: string;
    message_placeholder: string;
    name_label: string;
    name_placeholder: string;
    sign_up_newsletter_text: string;
    title: string;
    cta: SanityButton;
  };
}

export default function SanityContactUsForm({data}: Props) {
  const {
    register,
    // handleSubmit,
    // watch,
    setError,
    formState: {errors, isSubmitting, isSubmitSuccessful, isSubmitted, isDirty},
  } = useForm<{
    email: string;
    name: string;
    message: string;
    sign_up_newsletter: boolean;
    serverError?: string;
  }>({
    resolver: yupResolver(
      yup.object({
        name: yup.string().required('Name is required'),
        email: yup
          .string()
          .email('Please use  valid email')
          .required('Email is required'),
        message: yup.string().required('Message is required'),
        sign_up_newsletter: yup.boolean(),
      }),
    ),
    mode: 'all',
    reValidateMode: 'onChange',
  });

  const hasErrors = errors && !R.isEmpty(errors);
  const formRef = React.useRef<HTMLFormElement>(null);

  const fetcher = useFetcher();
  const response = fetcher?.data && JSON.parse(fetcher?.data);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (formRef?.current) {
      const formData = new FormData(formRef.current);
      fetcher.submit(formData, {method: 'post', action: '/api/contact'});
    }
  };

  const [token, setToken] = React.useState('');

  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      //@ts-expect-error
      reCaptchaKey={window.ENV.RECAPTCHA_KEY}
      scriptProps={{async: true, defer: true, appendTo: 'body'}}
    >
      <section className="">
        <header className="mb-11 text-center">
          <h3 className="text-xl tracking-wide mb-6 font-medium normal-case">
            {data.title}
          </h3>
          <p className="text-md md:text-lg !leading-snug font-normal">
            {data.description}
          </p>
        </header>
        <fetcher.Form
          className="font-normal"
          action="/api/contact"
          method="post"
          onSubmit={handleSubmit}
          ref={formRef}
        >
          {response && response.errorMessage && (
            <div className="mb-6 flex items-center justify-center rounded-sm border border-red p-4 text-sm text-red">
              <p>{response.errorMessage}</p>
            </div>
          )}
          {response && response.success && (
            <div className="mb-6 flex items-center justify-center rounded-sm border border-hk-orange p-4 text-sm text-hk-orange">
              <p>Thank you for your feedback. We will contact you shortly</p>
            </div>
          )}
          <div className="mb-10 grid gap-9 md:grid-cols-2">
            <fieldset className="md:col-span-1">
              <label htmlFor="name" className="mb-2 block">
                {data.name_label}
              </label>
              <input
                id="name"
                type="text"
                {...register('name')}
                placeholder={data.name_placeholder}
                disabled={response && response?.success}
                className="block w-full rounded-sm border border-solid border-hk-deep-gray py-3 px-4 text-md outline-none"
              />
              {hasErrors && errors.name && (
                <p className="md:text-lg !leading-snug font-normal mt-2 text-sm text-hk-red">
                  {errors.name.message}
                </p>
              )}
            </fieldset>
            <fieldset className="md:col-span-1">
              <label htmlFor="email" className="mb-2 block">
                {data.email_address_label}
              </label>
              <div className="relative">
                <input
                  id="email"
                  type="email"
                  {...register('email')}
                  placeholder={`${data.email_address_placeholder}`}
                  className="block w-full rounded-sm border border-solid border-hk-deep-gray py-3 pl-10 pr-4 text-md outline-none"
                />
                <div className="absolute top-1/2 left-4 -translate-y-1/2 text-slate-400">
                  <svg
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    className="w-4 fill-current"
                  >
                    <path d="M24 21h-24v-18h24v18zm-23-16.477v15.477h22v-15.477l-10.999 10-11.001-10zm21.089-.523h-20.176l10.088 9.171 10.088-9.171z" />
                  </svg>
                </div>
              </div>

              {hasErrors && errors.email && (
                <p className="md:text-lg !leading-snug font-normal mt-2 !text-sm text-hk-red">
                  {errors.email.message}
                </p>
              )}
            </fieldset>
            <div className="-mt-4 flex items-center space-x-2 md:hidden">
              <input
                type="checkbox"
                {...register('sign_up_newsletter')}
                className=""
              />
              <p className="text-md md:text-lg !leading-snug font-normal">
                {data.sign_up_newsletter_text}
              </p>
            </div>
            <fieldset className="md:col-span-2">
              <label htmlFor="message" className="mb-2 block">
                {data.message_label}
              </label>
              <textarea
                id="message"
                {...register('message')}
                placeholder={data.message_placeholder}
                className="block min-h-[132px] w-full rounded-sm border border-solid border-hk-deep-gray py-3 px-4 text-md outline-none"
              />
              {hasErrors && errors.message && (
                <p className="md:text-lg !leading-snug font-normal mt-2 text-sm text-hk-red">
                  {errors.message.message}
                </p>
              )}
            </fieldset>
          </div>
          <div className="flex items-center justify-between">
            <div className="hidden items-center space-x-2 md:flex">
              <input
                type="checkbox"
                {...register('sign_up_newsletter')}
                className=""
                disabled={response && response?.success}
              />
              <p className="text-md md:text-lg !leading-snug font-normal">
                {data.sign_up_newsletter_text}
              </p>
            </div>
            <div className="w-full md:w-auto">
              <button
                type="submit"
                disabled={
                  !isDirty ||
                  isSubmitting ||
                  isSubmitSuccessful ||
                  (response && response.success)
                }
                className={clsx(
                  'w-full rounded-sm px-6 py-4 disabled:opacity-50 md:w-auto',
                  data.cta.button_size === 'full-width' && 'w-full',
                )}
                style={{
                  backgroundColor: data.cta.button_bg_colour.value,
                  color: data.cta.button_text_colour.value,
                }}
              >
                {fetcher.state === 'submitting'
                  ? 'Subitting...'
                  : response && response?.success
                  ? 'Sent'
                  : data.cta.button_text}
              </button>
            </div>
            <RecaptchaComponent token={token} setToken={setToken} />
          </div>
        </fetcher.Form>
      </section>
    </GoogleReCaptchaProvider>
  );
}

function RecaptchaComponent({
  token,
  setToken,
}: {
  token: string;
  setToken: React.Dispatch<React.SetStateAction<string>>;
}) {
  const {executeRecaptcha} = useGoogleReCaptcha();
  const [dynamicAction, setDynamicAction] = React.useState('homepage');
  const [actionToChange, setActionToChange] = React.useState('');

  const clickHandler = React.useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }

    const result = await executeRecaptcha('dynamicAction');

    setToken(result);
  }, [dynamicAction, executeRecaptcha]);

  const handleTextChange = React.useCallback((event: any) => {
    setActionToChange(event.target.value);
  }, []);

  const handleCommitAction = React.useCallback(() => {
    setDynamicAction(actionToChange);
  }, [actionToChange]);

  React.useEffect(() => {
    if (!executeRecaptcha || !dynamicAction) {
      return;
    }

    const handleReCaptchaVerify = async () => {
      const token = await executeRecaptcha(dynamicAction);
      setToken(token);
    };

    handleReCaptchaVerify();
  }, [executeRecaptcha, dynamicAction]);
  return (
    <div>
      {/* <div>
        <p>Current ReCaptcha action: {dynamicAction}</p>
        <input type="text" onChange={handleTextChange} value={actionToChange} />
        <button onClick={handleCommitAction}>Change action</button>
      </div>
      <br /> */}
      {/* <button onClick={clickHandler}>Run verify</button> */}
    </div>
  );
}
