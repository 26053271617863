import React from 'react';
import type {SanityModuleProductBundle} from 'domains/modules';

export default function ProductBundle({
  bundle_shortcode,
}: Pick<SanityModuleProductBundle, 'bundle_shortcode'>) {
  return (
    <div className="py-10 md:py-20">
      <div
        className="bundler-target-element"
        data-bndlr-ccid={bundle_shortcode}
      ></div>
    </div>
  );
}
