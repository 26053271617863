import React from 'react';
import * as R from 'ramda';
import clsx from 'clsx';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Swiper as SwiperClass} from 'swiper/types';

//components
import SanityImage from 'components/SanityImage';

//styles
import 'swiper/swiper.min.css';

//domains
import {type SanityModuleCarousel as SantiyCarouselType} from 'domains/modules';

interface Props {
  data: SantiyCarouselType;
}

export default function SanityCarousel({data}: Props) {
  const carouselRef = React.useRef<SwiperClass>();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const hasSlides = data.carousel_slides && !R.isEmpty(data.carousel_slides);
  const lastIndex = hasSlides && data.carousel_slides.length - 3;
  return (
    <section className="">
      <div className="grid gap-8 md:hidden">
        {hasSlides &&
          data.carousel_slides.map((s) => {
            return (
              <div key={s._key} className="">
                <SanityImage
                  layout="responsive"
                  sizes={['50vw, 100vw']}
                  src={s?.asset._ref}
                  className="h-full object-cover"
                />
              </div>
            );
          })}
      </div>
      <div className="relative">
        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          onSwiper={(swiper) => {
            carouselRef.current = swiper;
          }}
          className="hidden !overflow-visible md:block"
          onSlideChange={(swiper: SwiperClass) => {
            const nextSlide = swiper.activeIndex;
            setActiveIndex(nextSlide);
          }}
        >
          {hasSlides &&
            data.carousel_slides.map((s) => {
              return (
                <SwiperSlide key={s._key}>
                  <div className="">
                    <SanityImage
                      layout="responsive"
                      sizes={['50vw, 100vw']}
                      src={s?.asset._ref}
                      className="h-full object-cover"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
        {hasSlides && data.carousel_slides.length > 3 && (
          <button
            onClick={() => carouselRef.current?.slidePrev()}
            className={clsx(
              'absolute left-0 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2',
              activeIndex === 0 && 'pointer-events-none opacity-50',
            )}
          >
            <PrevArrowIcon />
          </button>
        )}
        {hasSlides && data.carousel_slides.length > 3 && (
          <button
            onClick={() => carouselRef.current?.slideNext()}
            className={clsx(
              'absolute right-0 top-1/2 z-10 translate-x-1/2 -translate-y-1/2',
              activeIndex === lastIndex && 'pointer-events-none opacity-50',
            )}
          >
            <NextArrowIcon />
          </button>
        )}
      </div>
    </section>
  );
}

function NextArrowIcon() {
  return (
    <svg
      width="48"
      height="46"
      viewBox="0 0 48 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse cx="24" cy="22.5902" rx="24" ry="22.5905" fill="#212121" />
      <path
        d="M22.2204 15.4155L21.5199 16.0422C21.3798 16.2071 21.3798 16.471 21.5199 16.603L27.8595 22.5737L21.5199 28.5774C21.3798 28.7094 21.3798 28.9733 21.5199 29.1382L22.2204 29.765C22.3955 29.9299 22.6407 29.9299 22.8158 29.765L30.1713 22.8706C30.3114 22.7057 30.3114 22.4748 30.1713 22.3098L22.8158 15.4155C22.6407 15.2505 22.3955 15.2505 22.2204 15.4155Z"
        fill="white"
      />
    </svg>
  );
}

function PrevArrowIcon() {
  return (
    <svg
      width="48"
      height="46"
      viewBox="0 0 48 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        rx="24"
        ry="22.5905"
        transform="matrix(1 0 0 -1 24 22.5902)"
        fill="#212121"
      />
      <path
        d="M26.3546 30.0863L27.0865 29.4315C27.2329 29.2592 27.2329 28.9835 27.0865 28.8456L20.463 22.6076L27.0865 16.335C27.2329 16.1972 27.2329 15.9214 27.0865 15.7491L26.3546 15.0943C26.1717 14.922 25.9155 14.922 25.7325 15.0943L18.0477 22.2974C17.9014 22.4697 17.9014 22.7109 18.0477 22.8833L25.7325 30.0863C25.9155 30.2587 26.1717 30.2587 26.3546 30.0863Z"
        fill="white"
      />
    </svg>
  );
}
