// import React from 'react'
import {useMatches} from '@remix-run/react';

//components
import SanityImage from 'components/SanityImage';
import {Link} from 'components/Link';
import Container from 'components/Container';

//types
import type {SanityModuleHeroBanner} from 'domains/modules';
import {I18nLocale} from '~/lib/type';
interface Props {
  module: SanityModuleHeroBanner;
}

export default function HeroBanner({module}: Props) {
  const [root] = useMatches();
  const selectedLocale = root?.data?.selectedLocale as I18nLocale;
  const banner_ref =
    selectedLocale.language === 'ZH_TW'
      ? module.banner_image?.asset._ref
      : module.banner_image_en?.asset._ref;
  const link =
    selectedLocale.language === 'ZH_TW' ? module.link : module.link_en;
  if (link) {
    if (link._type === 'linkInternal') {
      return (
        <Link to={`${link.slug}`} key={module._key}>
          <section className="module--hero-banner">
            <Container>
              <SanityImage
                layout="responsive"
                // sizes={['50vw, 100vw']}
                src={banner_ref}
                className="w-full object-cover"
              />
            </Container>
          </section>
        </Link>
      );
    }
    if (link._type === 'linkExternal') {
      return (
        <a
          href={link.url}
          target={link.newWindow ? '_blank' : '_self'}
          rel="noreferrer noopener"
          key={module._key}
        >
          <section className="module--hero-banner">
            <Container>
              <SanityImage
                layout="responsive"
                // sizes={['50vw, 100vw']}
                src={banner_ref}
                className="w-full object-cover"
              />
            </Container>
          </section>
        </a>
      );
    }
  }
  return (
    <section className="module--hero-banner">
      <Container>
        <SanityImage
          // crop={image?.crop}
          // hotspot={image?.hotspot}
          layout="responsive"
          // sizes={['50vw, 100vw']}
          src={banner_ref}
        />
      </Container>
    </section>
  );
}
