import React from 'react';

//components
import SanityImage from 'components/SanityImage';
import {Link} from 'components/Link';

//domains
import type { FullImage } from 'domains/modules';

interface Props {
  module: FullImage;
}

export default function FullImage({module}: Props) {
  if (module.link) {
    if (module.link._type === 'linkInternal') {
      return (
        <Link to={`${module.link.slug}`} key={module._key}>
          <SanityImage
            layout="responsive"
            sizes={['50vw, 100vw']}
            src={module?.asset?._ref}
            className="w-full object-cover"
          />
        </Link>
      );
    }
    if (module.link._type === 'linkExternal') {
      return (
        <a
          href={module.link.url}
          target={module.link.newWindow ? '_blank' : '_self'}
          rel="noreferrer noopener"
          key={module._key}
        >
          <SanityImage
            layout="responsive"
            sizes={['50vw, 100vw']}
            src={module?.asset?._ref}
            className="w-full object-cover"
          />
        </a>
      );
    }
  }
  return (
    <SanityImage
      key={module._key}
      layout="responsive"
      sizes={['50vw, 100vw']}
      src={module?.asset?._ref}
      className="w-full object-cover"
    />
  );
}
