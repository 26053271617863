import React from 'react';
import {Image} from '@shopify/hydrogen';
import {Await, useLoaderData} from '@remix-run/react';
import {Suspense} from 'react';

//components
import Container from 'components/Container';
import {Link} from 'components/Link';
import SanityImage from 'components/SanityImage';

//types
import type {SanityModuleSelectedCollections} from 'domains/modules';

import {loader} from '~/routes/($lang)/index';

interface Props {
  module: SanityModuleSelectedCollections;
}
const SelectedCollections: React.FC<Props> = ({module}) => {
  const {featuredCollections} = useLoaderData<typeof loader>();
  return (
    <Suspense fallback="loading">
      <Await resolve={featuredCollections}>
        <section className="py-7">
          <Container>
            <ul className="grid gap-x-2 gap-y-12 md:gap-x-12 xl:gap-x-6 grid-cols-4 xl:grid-cols-8">
              {module.shopify_collections.map((c) => {
                const collection = featuredCollections?.find(
                  (collection: any) =>
                    c.shopify_collection.store.gid === collection?.id,
                );
                return (
                  <li key={c.shopify_collection._id} className="group">
                    <Link
                      to={`/collections/${c.shopify_collection.store.slug?.current}`}
                    >
                      <div className="aspect-w-16 aspect-h-16 mb-2 lg:mb-4 overflow-hidden rounded-full border border-slate-100 shadow-md transition-all duration-200 ease-out group-hover:shadow-lg">
                        <div className="p-1 lg:p-4 transition-transform duration-200 ease-out group-hover:scale-110">
                          {c?.collection_thumbnail?.asset?._ref ? (
                            <SanityImage
                              layout="responsive"
                              sizes={['50vw, 100vw']}
                              src={c?.collection_thumbnail?.asset?._ref}
                              className="w-full object-cover"
                            />
                          ) : c.shopify_collection.store.imageUrl ? (
                            <Image
                              data={{
                                url: c.shopify_collection.store.imageUrl ?? '',
                              }}
                              width={292}
                              height={292}
                              alt={c.shopify_collection.store.title}
                            />
                          ) : <div className="w-full h-full bg-white"></div>}
                        </div>
                      </div>
                      <div className="relative capitalize text-center text-xs lg:text-sm font-normal lg:font-bold lg:tracking-wide">
                        <span className="absolute left-1/2 -translate-x-1/2 w-full">
                          {collection?.title ??
                            c.shopify_collection.store.title}
                        </span>
                      </div>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </Container>
        </section>
      </Await>
    </Suspense>
  );
};

export default SelectedCollections;
