import React from 'react';

//components
import Container from 'components/Container';
import GoogleReviews from './GoogleReviews';
import FacebookReviews from './FacebookReviews';
import LooxReviews from './LooxReviews';

//types
import type {SanityModuleSocialReviews} from 'domains/modules';

interface Props {
  module: SanityModuleSocialReviews;
  slidesPerView?: number;
}

export default function SocialReviews({module, slidesPerView}: Props) {
  if (module.google_reviews || module.facebook_reviews) {
    return (
      <section className="py-7">
        <header className="text-center mb-4">
          <h2 className="text-xl font-bold uppercase tracking-wide">
            {module.section_title}
          </h2>
        </header>
        <LooxReviews
          module={{
            _type: 'module.looxReviews',
            _key: 'product-page-loox-reviews',
            active: true,
          }}
        />
        <Container>
          {module.google_reviews && (
            <GoogleReviews slidesPerView={slidesPerView} />
          )}
          {module.facebook_reviews && (
            <FacebookReviews slidesPerView={slidesPerView} />
          )}
        </Container>
      </section>
    );
  }
  return <></>;
}
