import React from 'react';

//components
import Container from 'components/Container';
import SanityImage from 'components/SanityImage';

//types
import type {
  SanityModuleGridSection,
  SanityImageTitleDescriptionBlock,
} from 'domains/modules';

interface Props {
  module: SanityModuleGridSection;
}

export default function GridSection({module}: Props) {
  return (
    <section className="module--grid-section py-14">
      <header className="col-span-12 mb-16 text-center">
        <h2 className="text-xl font-bold uppercase tracking-wide">
          {module.section_title}
        </h2>
      </header>
      <Container>
        <div className="grid grid-cols-12 gap-6">
          {module.blocks &&
            module.blocks.map((b) => {
              return <ImageTitleDescriptionBlock key={b._key} block={b} />;
            })}
        </div>
      </Container>
    </section>
  );
}

const ImageTitleDescriptionBlock: React.FC<{
  block: SanityImageTitleDescriptionBlock;
}> = ({block}) => {
  return (
    <article className="col-span-12 md:col-span-4 text-center">
      <div className="aspect-w-16 aspect-h-9 relative w-9/12 mx-auto mb-6">
        <SanityImage
          layout="fill"
          objectFit="contain"
          sizes={['50vw, 100vw']}
          src={block.image?.asset._ref}
        />
      </div>
      <div className="h-[60px] mb-6">
        <h5 className="text-md md:text-[20px] md:leading-[30px] font-semibold md:w-9/12 mx-auto">
          {block.title}
        </h5>
      </div>

      <p className="text-md md:text-lg !leading-snug font-normal">
        {block.description}
      </p>
    </article>
  );
};
