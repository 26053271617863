import clsx from 'clsx';
import {Link} from 'components/Link';

//utils
// import {generateSanityInternalPath} from 'domains/sanity';

//types
import type {SanityButton as SanityButtonType} from 'domains/modules';

interface Props {
  data: SanityButtonType;
}

export default function SanityButton({data}: Props) {
  //   const internalLink =
  //     data.link &&
  //     data.link._type === 'linkInternal' &&
  //     generateSanityInternalPath({
  //       type: data?.link?.reference?._type,
  //       slug:
  //         data?.link?.reference?.slug?.current ??
  //         data?.link?.reference?.store?.slug?.current,
  //     });
  return (
    <section
      className={clsx(
        data.button_position === 'center' &&
          data.button_size === 'normal' &&
          'flex justify-center',
        data.button_position === 'right' &&
          data.button_size === 'normal' &&
          'flex justify-end',
        data.button_size === 'full-width' && 'block w-full',
      )}
    >
      {data.link ? (
        data?.link?._type === 'linkInternal' ? (
          <Link
            to={`${data.link.slug}`}
            className={clsx(
              'inline-block rounded-sm px-2 py-3',
              data.button_size === 'full-width' && 'w-full',
            )}
            style={{
              backgroundColor: data.button_bg_colour?.value,
              color: data.button_text_colour?.value,
            }}
          >
            {data.button_text}
          </Link>
        ) : (
          <a
            href={data.link.url}
            target="_blank"
            rel="noreferrer noopener"
            className={clsx(
              'inline-block rounded-sm px-2 py-3',
              data.button_size === 'full-width' && 'w-full text-center',
            )}
            style={{
              backgroundColor: data.button_bg_colour?.value,
              color: data.button_text_colour?.value,
            }}
          >
            {data.button_text}
          </a>
        )
      ) : (
        <button
          className={clsx(
            'rounded-sm p-2',
            data.button_size === 'full-width' && 'w-full',
          )}
          style={{
            backgroundColor: data.button_bg_colour?.value,
            color: data.button_text_colour?.value,
          }}
        >
          {data.button_text}
        </button>
      )}
    </section>
  );
}
